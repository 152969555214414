<template>
    <div class="study-history-item mb-16">
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ item.title }}
            </div>
        </div>
        <PDFView :url="item.pdf" />
        <div class="item-text">
            <content-renderer :content="item.content" />
        </div>
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('study.landing.download') }}
            </div>
            <a :href="item.pdf">{{ item.title }}.pdf</a>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import PDFView from '@/components/PDFView.vue';
import ContentRenderer from '../../components/ContentRenderer.vue';

export default {
    components: {
        PDFView,
        ContentRenderer,
    },
    data() {
        return {
            item: {},
        };
    },
    watch: {
        $route() {
            this.View();
        },
    },
    mounted() {
        this.View();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async View() {
            try {
                const res = await API.BookHistory.PublicFetchResource(this.$route.params.id, {
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.item = res.data;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">
.study-history-item{
  .item-text{
    margin-top: 25px;
  }
  .sub-block{
    margin-top: 25px;
    a{
      font-size: 16px;
      font-weight: 700;
      color: $color-orange;
      margin-left: 15px;
      text-decoration:none;
    }
  }
}

</style>
